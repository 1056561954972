#contactBody {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Hind Guntur', sans-serif;
    color: #fff;
    font-size: 1.0em;
}

#contactInfo {
    background-color: #333;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5px;
    width: 30%;
    margin-right: 2%;
}

#headerContainer {
    height: 120px;
    z-index: 1;
}

#contactHeader {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 3.0em;
}

#clickSponsor {
    color: #8080ff;
    text-decoration-line: underline;
    cursor: pointer;
}

#gmapCanvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 700px;
    margin: 0;
    border-radius: 5px;

}

#mapOuter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 700px;
    
}

#map {
    background-color: #333;
    border-radius: 5px;
}
#headerContainer {
    height: 120px;
    z-index: 1;
}

#sponsorHeader {
    font-size: 2.5em;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
}

#highlightLine {
    background-color: #fdb71c;
    height: 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

#sponsorBody {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #fff;
    font-family: "Hind Guntur", sans-serif;
}

#tier1 {
    color: #fff;
    font-family: "Hind Guntur", sans-serif;
    font-size: 2.5em;
    text-align: center;
    margin: 10px;
}

#text {
    font-size: 1.5em;
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    text-align: center;
}

#sponsorLogo {
    width: 200px;
    height: 200px;
    margin: 20px;
}

#sponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    width: 400px;
    padding-bottom:100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
}

#otherTier {
    color: #fff;
    font-family: "Hind Guntur", sans-serif;
    text-align: center;
    margin: 10px;
    font-size: 1.5em;
}

#sponsorBlock {
    display: flex;
    flex-direction: column;
}

#tier1Body {
    display: flex;
    flex-direction: row;
}

#pageContainer {
    position: relative;
    min-height: 100vh;
}

#contentWrap {
    padding-bottom: 2.5rem;
}
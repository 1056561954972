#headerContainer {
    height: 120px;
    z-index: 1;
}

#aboutTitle {
    font-family: "Montserrat", sans-serif;
    color: white;
    text-align: center;
    font-size: 3.0em;
    /* margin-bottom: 0px; */
    margin-top: auto;
    margin-bottom: auto;
}

#banner {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 270px;
    z-index: -1;
    opacity: 0.5;
}

#bannerMount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 120px;
}

#aboutBody {
    font-family: "Hind Guntur", sans-serif;
    color: white;
    text-align: center;
    font-size: 1.5em;
    margin-top: 100px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
body {
    overflow-x: hidden;
}

#overlay {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

#videoMount {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
}

#reveal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* Use viewport width */
    height: 120vh;
    /* Use viewport height */
    object-fit: cover;
    /* Maintains aspect ratio while filling */
    z-index: -1;
    opacity: 0.5;
}

#title {
    font-family: "Zuume Black Italic";
    margin-top: -8vw;
    color: var(--stl-yellow);
    font-size: 30vw;
    letter-spacing: 0px;
    animation: glitch 1s linear infinite;
    z-index: -1;
    filter:
        drop-shadow(0 0 8px rgba(255, 198, 0, 0.5)) drop-shadow(0 0 16px rgba(255, 198, 0, 0.35)) drop-shadow(0 0 24px rgba(255, 198, 0, 0.2));
    text-shadow: none;
}

/* Also update the pseudo-elements for consistency */
#title:before,
#title:after {
    content: attr(title);
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    filter:
        drop-shadow(0 0 8px rgba(255, 198, 0, 0.5)) drop-shadow(0 0 16px rgba(255, 198, 0, 0.3));
    text-shadow: none;
}

#desc {
    font-family: "Zuume Black Italic";
    color: var(--off-white);
    font-size: 9vw;
    font-weight: 500;
    letter-spacing: 1.5px;
    margin-top: 20vh;
    /* margin-bottom: -15%; */
    animation: glitch 1.43s linear infinite;
    filter:
        drop-shadow(0 0 4px rgba(255, 255, 255, 0.4)) drop-shadow(0 0 8px rgba(255, 255, 255, 0.25)) drop-shadow(0 0 12px rgba(255, 255, 255, 0.15));
    text-shadow: none;
}

#desc:before,
#desc:after {
    content: attr(title);
    position: absolute;
    left: 0;
    filter:
        drop-shadow(0 0 4px rgba(255, 255, 255, 0.4)) drop-shadow(0 0 8px rgba(255, 255, 255, 0.2));
    text-shadow: none;
}

/* #location {
    font-family: "Montserrat";
    color: var(--off-white);
    font-size: 23px;

    font-weight: 700;

    margin-top: -3px;

    /* margin-top: -80px; */
/* } */

#trophyCanvas {
    width: 33vw;
}

#trophyVideo {
    width: 50vw;
    overflow: hidden;
    position: relative;
    z-index: 0;
    margin-left: -10vw;
}

#gradient {
    position: absolute;
        background: linear-gradient(to bottom,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.039) 8.5%,
            hsla(0, 0%, 0%, 0.094) 16.5%,
            hsla(0, 0%, 0%, 0.162) 23.9%,
            hsla(0, 0%, 0%, 0.24) 31%,
            hsla(0, 0%, 0%, 0.326) 37.7%,
            hsla(0, 0%, 0%, 0.417) 44.1%,
            hsla(0, 0%, 0%, 0.51) 50.3%,
            hsla(0, 0%, 0%, 0.602) 56.4%,
            hsla(0, 0%, 0%, 0.691) 62.4%,
            hsla(0, 0%, 0%, 0.774) 68.3%,
            hsla(0, 0%, 0%, 0.848) 74.3%,
            hsla(0, 0%, 0%, 0.91) 80.4%,
            hsla(0, 0%, 0%, 0.958) 86.7%,
            hsla(0, 0%, 0%, 0.989) 93.2%,
            hsl(0, 0%, 0%) 100%);

    height: 20vw;
    width: 100vw;

    opacity: 1;

    margin-top: -20vw;
}


#statWrapper {
    /* font-family: "Nunito Sans", sans-serif; */

    color: var(--off-white);

    background-color: var(--black);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0px;

    height: 100vh;
    width: 100%;
    /* padding-bottom: 5vw; */
    /* margin-bottom: 15vw; */

}

#statContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;

    /* margin-bottom: 15vw; */
    /* padding-bottom: 5vw; */
    /* margin-bottom: 15vw; */
}

.stat {
    font-family: "Zuume Black Italic";
    color: var(--off-white);
    /* text-shadow: 0 0 3px rgba(255, 255, 255, 0.7),
        0 0 5px rgba(255, 255, 255, 0.5),
        0 0 7px rgba(255, 255, 255, 0.3); */
    display: flex;
    align-items: center;
    flex-direction: row;
}

#stat1 {
    margin-right: 10vw;
    margin-bottom: -2vw;

}

#stat2 {
    margin-right: 5vw;
    margin-bottom: -2vw;

}

#stat3 {
    margin-right: 0vw;
    margin-bottom: -2vw;
}

#stat4 {
    margin-right: -5vw;
    margin-bottom: -2vw;
}

.stat-number {
    font-family: "Zuume Black Italic";
    color: var(--stl-yellow);
    font-size: 12.5vw;
    line-height: 1;
    margin-right: 1vw;
    filter:
        drop-shadow(0 0 10px rgba(255, 198, 0, 0.7)) drop-shadow(0 0 20px rgba(255, 198, 0, 0.5)) drop-shadow(0 0 30px rgba(255, 198, 0, 0.3));
}

/* Style for the text part */
.stat-text {
    font-family: "Zuume Black Italic";
    color: var(--off-white);
    font-size: 5vw;
    line-height: 80%;
    text-align: left;
    filter:
        drop-shadow(0 0 6px rgba(255, 255, 255, 0.7)) drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
}


@keyframes flicker {
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
        opacity: 1;
        /* text-shadow: 0 0 6px rgba(255, 255, 255, 0.7),
            0 0 10px rgba(255, 255, 255, 0.5),
            0 0 15px rgba(255, 255, 255, 0.3); */
    }

    20%, 22%, 24%, 55% {
        opacity: 0.5;
        /* text-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
            0 0 8px rgba(255, 255, 255, 0.3); */
    }
}

@keyframes pulsate {
    0% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flicker1 {
    0%, 25%, 27%, 29%, 31%, 70%, 72%, 100% {
        opacity: 1;
    }

    26%, 28%, 30%, 71% {
        opacity: 0.3;
    }
}

@keyframes flicker2 {
    0%, 10%, 12%, 14%, 50%, 52%, 80%, 82%, 100% {
        opacity: 1;
    }

    11%, 13%, 51%, 81% {
        opacity: 0.2;
    }
}

@keyframes flicker3 {
    0%, 18%, 20%, 22%, 60%, 62%, 90%, 92%, 100% {
        opacity: 1;
    }

    19%, 21%, 61%, 91% {
        opacity: 0.3;
    }
}

@keyframes flicker4 {
    0%, 5%, 7%, 9%, 40%, 42%, 75%, 77%, 100% {
        opacity: 1;
    }

    6%, 8%, 41%, 76% {
        opacity: 0.25;
    }
}

#stat1 {
    animation: pulsate 1.5s ease-in-out infinite alternate, flicker1 8.3s linear infinite;
}

#stat2 {
    animation: pulsate 1.5s ease-in-out infinite alternate, flicker3 12.23352s linear infinite;
}

#stat3 {
    animation: fadeIn 0.5s ease-in-out, float 3s ease-in-out infinite, flicker1 10.5s linear infinite;
}

#stat4 {
    animation: pulsate 1.5s ease-in-out infinite alternate, flicker4 9.8s linear infinite;
}

@keyframes pulsate {
    0% {
        opacity: 0.8;

    }

    100% {
        opacity: 1;
    }
}

/* .stat b {
    font-size: 6vw;
    font-family: "Zuume Black Italic";
    color: var(--stl-yellow);

    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
        0 0 15px rgba(255, 255, 255, 0.6),
        0 0 20px rgba(255, 255, 255, 0.4),
        0 0 25px rgba(255, 255, 255, 0.3);
}

.stat {
    text-shadow: 0 0 6px rgba(255, 255, 255, 0.7),
        0 0 7px rgba(255, 255, 255, 0.5),
        0 0 10px rgba(255, 255, 255, 0.3);
}

#stat1 {
    margin-top: 10vw;
    font-size: 10vw;
}

#stat1 b {
    font-size: 100%;
    color: var(--stl-orange);


} */

/* #stat2 {
    margin-bottom: -30px !important;
}

#stat3 {
    margin-bottom: -30px !important;
} */

.stat {
    font-size: 6vw;
    font-family: "Zuume Black Italic";
    vertical-align: middle;
    z-index: 1;
    /* margin-right: 8vw; */
    margin-bottom: -1.5%;
}

@keyframes glitch {
    2%, 64% {
        transform: translate(2px, 0) skew(0deg);
    }

    4%, 60% {
        transform: translate(-2px, 0) skew(0deg);
    }

    62% {
        transform: translate(0, 0) skew(5deg);
    }
}

#title:before {
    animation: glitchTop 1s linear infinite;
    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

#title:after {
    animation: glitchBotom 1.5s linear infinite;
    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

#desc:before,
#desc:after {
    content: attr(title);
    position: absolute;
    left: 0;
}

#desc:before {
    animation: glitchTop 1s linear infinite;
    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

#desc:after {
    animation: glitchBotom 1.5s linear infinite;
    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchTop {
    2%, 64% {
        transform: translate(2px, -2px);
    }

    4%, 60% {
        transform: translate(-2px, 2px);
    }

    62% {
        transform: translate(5px, -1px) skew(-13deg);
        /* Reduced from 13px to 5px */
    }
}

@keyframes glitchBotom {
    2%, 64% {
        transform: translate(-2px, 0);
    }

    4%, 60% {
        transform: translate(2px, 0);
        /* Changed to make it more symmetrical */
    }

    62% {
        transform: translate(-5px, 5px) skew(21deg);
        /* Reduced from -22px to -5px */
    }
}

#pageContainer {
    position: relative;
    min-height: 100vh;
}

#contentWrap {
    height: 120vh;
    padding-bottom: 2.5rem;
}

/* Add responsive styles for mobile */
@media screen and (max-width: 768px) {
    #title {
        font-size: 45vw;
        /* Increased from 30vw */
        margin-top: -13vw;
    }

    #desc {
        font-size: 14vw;
        /* Increased from 9vw */
        margin-top: 30vh;
    }

    .stat {
        font-size: 8vw;
        /* Increased from 6vw */
    }

    .stat b {
        font-size: 8vw;
        /* Increased from 6vw */
    }

    #stat1 {
        font-size: 12vw;
        /* Increased from 10vw */
    }
}

/* Additional styles for very small screens */
@media screen and (max-width: 480px) {
    #title {
        font-size: 55vw;
    }

    #desc {
        font-size: 16vw;
        margin-top: 12vh;
    }

    #statWrapper {
        align-items: center;
        /* Center stats on small screens */
        margin-right: 0;
    }

    .stat {
        margin-right: 0;
        text-align: center;
    }
}
#separator {
    border-right: 1.25px white solid;
    height: 1.5vw;
    margin-left: 2vw;
}

#mainHeader {
    font-family: "Montserrat", sans-serif;
    color: white;
    text-align: center;
    font-size: 0.5vw;
    font-weight: bold;
    /* margin-left: 3%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* #branding {
    font-family: "Zuume Black Italic", sans-serif;
    font-size: 60px;
    margin-top: 20px;
    color: white;
} */

#sideHeader {
    font-family: "Hind Guntur", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
    color: white;
    /* margin-right: 1%; */
    /* padding-left: 2vw; */
}

#sideHeader>a {
    margin-right: 1vw;
    vertical-align: middle;
    text-align: center;
    color: white;
    text-decoration: none;
    margin-top: 0.2vw;
}

#sideHeader>a:hover {
    text-shadow: 0 0 10px var(--off-white), 0 0 20px var(--off-white), 0 0 42px var(--stl-orange), 0 0 82px var(--stl-orange), 0 0 92px var(--stl-orange);
    transition: 0.2s;
}

#lionsLogo {
    height: 2vw;
    margin-right: 15px;
}

#combinedHeader {
    z-index: 99;
    transition: 0.2s;
    background-color: transparent;
    width: 100%;
    height: 3.5vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrolled {
    background: rgba(51, 51, 51, 0.5);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
}
#socialMedia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #333;
    padding-top: 20px;
    padding-bottom: 30px;
}

#icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 20px;
}

#icons img {
    margin-right: 15px;
    margin-left: 15px;
    height: 25px;
}

#mediaTitle {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 20px;
    margin-right: 20px;
    font-family: 'Montserrat', sans-serif;
}

#footerBanner {
    position: absolute;
    bottom: -15%;
    width: 100%;
    height: 2.5rem;
}
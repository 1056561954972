@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Guntur&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap');

@font-face {
  font-family: 'Zuume Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Zuume Black Italic'), url('../assets/Zuume Black Italic.woff') format('woff');
}

:root {
  --stl-orange: #cf6106;
  --stl-yellow: #fdb71c;
  --off-white: #f1f1f1;
  --black: #000
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.acrylic {
  padding: 4em 6em;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin: 7px;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}